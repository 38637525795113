* {
  font-family: "Poppins", system-ui;
  user-select: none; /* Prevents the I-beam cursor */
  cursor: default; 
}

/* Exclude buttons, a tags and icons from the global rule */
button, a, i {
  cursor: pointer;   /* Show pointer (hand) cursor */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.overlay-container {
  position: absolute;
  top: 15%;
  left: 10%;
  width: 100%;
  max-width: 900px;
  max-height: 840px;
}

.overlay {
  width: 100%;
  max-width: 900px;
  max-height: 840px;
  padding: 4rem 6rem;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.375);
  border: 1px solid rgba(255, 255, 255, 0.125);
  position: absolute;
}

.overlay__bold:hover {
  color: #da3d63;
  transition: color 0.5s;
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
}

.overlay__inner {
  max-width: 40rem;
}

.overlay__title {
  font-size: 1.875rem;
  line-height: 2.75rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin-bottom: 2rem;
  border-bottom: solid black 7px;
}

.overlay__picture {
  float: right;
  width: 100px;
  border-radius: 50%;
}

.overlay__description {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 3rem;
}

.overlay__btns {
  width: 100%;
  max-width: 40rem;
  display: flex;
}

.overlay__btn {
  width: 50%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: black;
  background: black;
  border: none;
  border-radius: 0.5rem;
  transition: transform 150ms ease;
}

.overlay__btn:hover {
  transform: scale(1.1);
}

.overlay__btn--transparent {
  font-size: 2.5rem;
  background: transparent;
  color: black;
  margin-right: 0.75rem;
}

.overlay__btn-emoji {
  margin-left: 0.375rem;
}

a {
  text-decoration: none;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-contrast: high) {
  .orb-canvas {
    display: none;
  }
}

@media only screen and (max-width: 1350px) {
  .overlay {
    width: initial;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1140px) {
  .overlay {
    padding: 5rem 2.5rem;
  }
}

@media only screen and (max-width: 840px) {
  body {
    padding: 1.5rem;
  }

  .overlay-container {
    top: 1%;
    left: 0;
    margin: 0 14px;
    width: 94%;
  }

  .overlay {
    padding: 4rem;
    height: 100vh;
    width: auto;
  }

  .overlay__picture {
    width: 50px;
    margin-top: 20px;
  }

  .overlay__title {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .overlay__description {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
  }

  .overlay__btn--transparent {
    font-size: 16px !important;
  }

  #tsparticles {
    width: initial !important;
  }
}

@media only screen and (max-width: 600px) {
  .overlay {
    padding: 1.5rem;
  }

  .overlay__btns {
    flex-wrap: wrap;
  }

  .overlay__btn {
    width: 25%;
    font-size: 0.75rem;
    margin-right: 0;
  }

  .overlay__btn:first-child {
    margin-bottom: 1rem;
  }
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
